import React from 'react';
import "./AC3.css";

function AC3() {
  return (
    <div className="values-container">
      <div className="text-container">
        <p>We are passionate about what we do when it comes to teaching, learning, developing skills, and exchanging knowledge. We develop leaders with inspiring future visions. Collaboration enables us to create services that truly add value to the real-world experiences, employment, and careers of our learners.</p>
        <h2 className="our-values">...Our Values</h2>
      </div>
      <div className="image-container">
        <img src="https://sapiencecollege.edu.au/wp-content/uploads/2024/04/iStock-1210745478.webp" alt="Group of students walking on campus" />
      </div>
    </div>
  );
}

export default AC3;