import React from 'react';
import "./AC4.css"

function AC4() {
  return (
    <div className="container">
      <div className="content">
        <h1 className="title">Our Mission...</h1>
        <p className="description">Sapience College strives to provide students with training and assessment options that are of exceptional quality, flexible, and affordable. By doing so, Sapience College contributes to the growth and sustainability of Australian businesses by supplying them with skilled and knowledgeable workers who are well-prepared for their roles.</p>
      </div>
      <div className="image-container2">
        <img src="https://sapiencecollege.edu.au/wp-content/uploads/2024/04/pexels-mitchel3uo-5896356-1024x1024.jpg" alt="Melbourne" className="image" />
      </div>
    </div>
  );
}

export default AC4;