// Footer.js
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="footer-container">
          <div className="footer-locations">
            <h3>LOCATIONS</h3>
            <address>
              Suite 1.09, Level 1, 425 Docklands Dr Docklands
              <br />
              VIC 3008
              <br />
              <a href="mailto:admissions@sapiencecollege.edu.au">
                admissions@sapiencecollege.edu.au
              </a>
              <br />
              +61 477 471 631
            </address>
          </div>
          <div className="footer-navigation">
            <h3>MAIN NAVIGATION</h3>
            <ul className="foot-nav">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/About">About</Link>
              </li>
              <li>
                <Link to="/Contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="footer-logo">
            <img
              src="https://sapiencecollege.edu.au/wp-content/uploads/2024/04/Untitled-design-32-1536x1283-1-1024x855.png"
              alt="Sapience College Logo"
            />
          </div>
        </div>
      </div>
      <div className="fdisc">
        <p>
          Copyright © 2024 Sapience College, RTO Code: 45185, Cricos Code:
          04016D All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
