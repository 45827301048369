import React from 'react';
import "./AC2.css";
const AC2 = () => {
  return (
    <div className="mission-and-values">
      <h2>Mission & Values</h2>
      <div className="underline"></div>
      <p>
        Sapience College strives to provide students with training and assessment
        options that are of exceptional quality, flexible, and affordable. By
        doing so, Sapience College contributes to the growth and sustainability
        of Australian businesses by supplying them with skilled and
        knowledgeable workers who are well-prepared for their roles.
      </p>
    </div>
  );
};

export default AC2;