import React from "react";
function Count3() {
  return (
    <div className="container2">
      <div className="text-container2">
        <div className="tc">
          <h1>Want to enroll Apply Now</h1>
        </div>

        <button className="btn">Apply Now</button>
      </div>
      {/* <div className="image-container2">
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
      </div> */}
    </div>
  );
}

export default Count3;
