import React from "react";
import "./Googlemap.css";
const Googlemap = () => {
  const iframeTitle = "Dynamic Content";
  return (
    <div className="ggmap">
      <address id="address">
        Suite 109 Level 1 425 Docklands Dr DOCKLANDS Victoria 3008<br></br>
        admissions@sapience college.edu.au<br></br> +61 477 471 631
      </address>
      <div className="mmap">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.958429907648!2d144.93661687569482!3d-37.81444267197544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65de0009058cf%3A0x5d41ed85dae1b774!2sSuite%20109%20Level%201%2F425%20Docklands%20Dr%2C%20Docklands%20VIC%203008%2C%20Australia!5e0!3m2!1sen!2sin!4v1720003325334!5m2!1sen!2sin"
          title={iframeTitle}
          width="600"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Googlemap;
