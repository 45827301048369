import React from 'react';
import "./AC1.css";
function AC1() {
  return (
    <div className="container">
      <div className="image-container">
        <img src="https://sapiencecollege.edu.au/wp-content/uploads/2024/04/australia-3905135_640.jpg" alt="City Skyline" />
      </div>
      <div className="text-container22">
        <h2>About Us...</h2>
        <p>
          Sapience College, identified by RTO Number 45185 and CRICOS
          Number 04016D, is a recognised educational institution
          committed to offering students a dynamic and distinctive
          learning journey.
        </p>
        <p>
          The primary goal of Sapience College is to equip students, the
          community, and the market with exceptional human resources
          that possess the necessary skills to meet the contemporary
          demands of the 21st century in Australia and globally. The
          college strongly values the pursuit of excellence in teaching,
          leadership, and teamwork, while also fostering a deep
          appreciation for Vocational Education and Training.
        </p>
      </div>
    </div>
  );
}

export default AC1;
