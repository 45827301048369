import React from 'react'
import C1 from './C1'
import Navbar from '../Navbar'
import "./Contact.css"
import Googlemap from './Googlemap'
import C2 from './C2'
import Footer from '../Footer'
const Contact = () => {
  return (
    <div className='CBody'>
        <Navbar/>
        <C1/>
        <h1 className="Map-d">Our Map & Direction</h1>
        <Googlemap/>
        <C2/>
        <Footer/>
    </div>
  )
}

export default Contact