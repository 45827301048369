import React from 'react';
import "./H4.css"
function H4() {
  return (
    <div className="about-container">
      <div className="about-content">
        <h2>About Sapience College</h2>
        <p>
          At Sapience College, we understand that pursuing education and skills is a significant investment, both in terms of time and finances. That's why we're committed to offering flexible and affordable options to help you achieve your academic and career goals.
        </p>
        <blockquote>
          <p>
            Tailored Learning Experience: Our programs are designed to accommodate the diverse needs and schedules of our students.
          </p>
        </blockquote>
      </div>
      <div className="about-image">
        <img src="https://s39613.pcdn.co/wp-content/uploads/2016/03/iStock_000037379776_Medium.160328.jpg" alt="Students working together" />
      </div>
    </div>
  );
}

export default H4;