import React from "react";
import "./Home.css";
import Cont2 from "./H2";
import Count3 from "./H3";
import Cont4 from "./H4";
import Cont5 from "./H5";
import Footer from "../Footer";
import Navbar from "../Navbar";

const Home = () => {
  return (
    <div className="HBody">
      <Navbar/>

    <div className="Body0">
      <div className="text-overlay0">
        <div className="college-name">Sapience College</div>
        <div className="slogan">Start your career & pursue your passion</div>
        <div className="buttons">
          <button className="learn-more">Learn More</button>
          <button className="contact-us">Contact Us</button>
        </div>
      </div>
    </div>    
    <Cont2 />
    <Count3/>
    <Cont4/>
    <Cont5/>
    <Footer/>
    </div>
  );
};

export default Home;
