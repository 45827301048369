import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import AC1 from './AC1'
import AC2 from './AC2'
import AC3 from './AC3'
import AC4 from './AC4'
import "./About.css"

const About = () => {
  return (
    <div className='Abodd'>
        <Navbar/>
        <AC1/>
        <AC2/>
        <AC3/>
        <AC4/>
        <Footer/>
    </div>
  )
}

export default About