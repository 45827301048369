import React from "react";
import "./H3.css";
function H3() {
  return (
    <div className="container2">
      <div className="text-container2">
        <div className="tc">
          <h1>Looking to enroll in our courses</h1>
          <p>Fill the application form. Take the first step towards success.</p>
        </div>

        <button className="btn">APPLICATION FORM</button>
      </div>
      {/* <div className="image-container2">
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
      </div> */}
    </div>
  );
}

export default H3;
