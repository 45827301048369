import React from 'react';
import "./Navbar.css";
import { Link } from 'react-router-dom';
const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="logo">
        <img src="https://sapiencecollege.edu.au/wp-content/uploads/2024/04/Untitled-design-32-1536x1283-1-1024x855.png" alt="Sapience College" />
      </div>
      <ul className="nav-links">
        <li><Link to ="/">Home</Link></li>
        <li><Link to ="/About">About</Link></li>
        <li><Link to ="/Contact">Contact Us</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;