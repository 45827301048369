import React from 'react';
import './H2.css'
const H2 = () => {
  return (
    <div className="container000">
      <h2 className="title000">Why us</h2>
      <div className="features">
        <div className="feature">
          <div className="icon">
            <i className="fas fa-book"></i>
          </div>
          <div className="content000">
            <h3>Weekdate & Weekend Classes</h3>
            <p>Sapience College Provides Week day and Weekend Batches for Students.</p>
          </div>
        </div>
        <div className="feature">
          <div className="icon">
            <i className="fas fa-user-graduate"></i>
          </div>
          <div className="content">
            <h3>Experienced Trainers</h3>
            <p>We have Experienced Trainers, making sure to provide best Education Experience.</p>
          </div>
        </div>
        <div className="feature">
          <div className="icon">
            <i className="fas fa-train"></i>
          </div>
          <div className="content">
            <h3>Public Transport Accessibility</h3>
            <p>Next to Tram Stop, Easy Access to Location.</p>
          </div>
        </div>
        <div className="feature">
          <div className="icon">
            <i className="fas fa-map-marker-alt"></i>
          </div>
          <div className="content">
            <h3>Great Location</h3>
            <p>Next to sea View in Docklands, Great Location to have fun after Classes.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default H2;